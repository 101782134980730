<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getSalesChannelsSetting"
    ></select-shop>

    <CCard class="shadow-sm">
      <CCardBody style="padding: 0%">
        <CRow class="mt-3">
          <CCol md="12" class="text-center">
            <div class="d-inline-flex align-items-start">
              <h2 class="font-weight-medium text-dark mb-0">
                {{ $t("salesChannels") }}
              </h2>
            </div>
            <h6 class="text-description">
              {{ $t("channelsDescriptionConfig") }}
            </h6>
          </CCol>
        </CRow>
        <hr />
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(list, index) in salesChannel" :class="[{ 'table-light': list.enabled === true}]"
            :key="index"
          >
            <div class="row">
              <div class="col">
                <img :src="list.img" class="img-fluid rounded-circle" width="60px" />
                {{ $t(list.name) }}
              </div>
              <div class="col text-right">
                <!-- {{ list.enabled }}  @update:checked="updateChannel(item)"-->
                <CSwitch
                  color="success"
                  :checked.sync="list.enabled"
                  @update:checked="updateChannelConfig(list, index)"
                
                />
                <div class="row" v-if="list.enabled === true">
                  <div class="col-10">
                    <div  class="form-group row">
                      <label
                        class="col-sm-2 col-lg-2 col-form-label text-right text-dark w-100 w-sm-auto"
                      >
                        GP (%)
                      </label>
                      <div class="col-sm-10 col-lg-10"  v-if="list.isEdit === false" >
                        <CInput
                         v-model.number="list.gpPercent"
                          placeholder="GP (%)"
                          type="number"
                          disabled
                        />
                      </div>
                      <div v-else class="col-sm-10 col-lg-10">
                        <CInput
                          v-model.number="list.gpPercent"
                          placeholder="GP (%)"
                          type="number"
                        />
                      </div>
                      </div>
                    </div>
                  <div class="col-2" v-if="list.isEdit === false">
                    <button 
                      class="mt-1 btn btn-primary btn-sm btn-block"
                      @click="changeItemEdit(index)"
                    >
                      {{ $t("edit") }}
                    </button>
                  </div>
                  <div class="col-2" v-else>
                    <button
                      class="mt-1 btn btn-success btn-sm btn-block"
                      @click="updateChannelConfig(list,index)"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import pos from "@/services/local";
import util from "@/util/util";
import { mapGetters } from "vuex";
import permis from "@/util/permission";
import { CNavItem } from "@coreui/vue";

export default {
  components: {},
  data() {
    return {
      loadingButton: true,
      loading: true,
      salesChannel: [],
      isEditItem : false,
    };
  },
  created() {
    this.getSalesChannelsSetting();
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "shopsetting", "isOwner"]),
    isPermission() {
      return permis.findPermissionRead("setting", this.$route.path);
    },
    uid() {
      return `${localStorage.getItem("uid")}`;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
  },
  methods: {
    getSalesChannelsSetting() {
      let params = {
        shopObjectId: this.shopObjectId,
        type:'ONLINE'
      }
      const shopObjectId = this.shopObjectId
   
      const headers = { 
            shopObjectId: shopObjectId ,
  
      }
      
      pos({
        url: "/api/v3.0/sellingchannel/list",
        params: params,
        headers :headers ,
        method: "GET",
      }).then((res) => {

        let data = res.data.data;
        let detail = [];
        for (let i = 0; i < data.length; i++) {
            detail.push({
            objectId: data[i].objectId,
            enabled: data[i].enabled,
            gpPercent: data[i].gpPercent,
            id: data[i].id,
            name: data[i].name,
            orderIndex: data[i].orderIndex,
            typeGroup: data[i].typeGroup,
            img: util.getDeliveryImg(data[i].id),
            isEdit: false
            });
        }
        this.salesChannel = detail
      });
    },
    changeItemEdit(index){
        this.salesChannel[index].isEdit = !this.salesChannel[index].isEdit
    },
    updateChannelConfigByEnable(item,index){
        console.log(item);
    },
    updateChannelConfig(item, index){
        
        let data =  {
                objectId: item.objectId,
                enabled : item.enabled,
                gpPercent : item.gpPercent
        }

        const shopObjectId = this.shopObjectId

        const headers = { 
            shopObjectId: shopObjectId 
        }

        pos({
            method: 'PATCH',
            headers :headers,
            url: '/api/v3.0/sellingchannel/update',
            data: data,
        })
        .then((res) => {
            this.salesChannel[index].isEdit = false
        })
        .catch((error) => {
          console.error(error)
        })
        
    }
  },
};
</script>
